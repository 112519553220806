<template>
  <div class="body-box">
    <!-- <div v-for="(item, index) in tempList" :key="index" v-html="item.content"></div> -->
    <!--    导航盒子-->
    <nav-box></nav-box>
    <div class="box">
      <i class="el-icon-close" @click="quit"></i>
    </div>
    <el-card class="box-card">
      <!-- logo部分 -->
      <div>
        <img src="" alt=""/>
        <span>欢迎注册!</span>
      </div>

      <!-- 步骤条 -->
      <el-steps :active="activeIndex" finish-status="success" align-center>
        <el-step title="注册用户"></el-step>
        <el-step title="填写基础信息"></el-step>
        <el-step title="完善企业资料"></el-step>
        <el-step title="资质审核"></el-step>
      </el-steps>

      <el-form
        ref="addFormRef"
        :model="tempForm"
        :rules="rules"
        label-width="150px"
      >
        <el-row class="form-box">
          <el-col :span="12">
            <el-form-item label="机构名称" prop="name">
              <el-input v-model="tempForm.name" placeholder="请输入机构名称/个人名称">
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="统一社会代码" prop="socialCode">
              <el-input v-model="tempForm.socialCode" placeholder="请输入统一社会信用代码"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="负责人" prop="principal">
              <el-input v-model="tempForm.principal" placeholder="请输入负责人"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="营业执照有效期起" prop="addvalidity">
              <el-date-picker
                v-model="tempForm.addvalidity"
                align="right"
                type="date"
                placeholder="选择日期"
                :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="营业执照有效期至" prop="editvalidity">
              <el-date-picker
                v-model="tempForm.editvalidity"
                type="date"
                placeholder="选择日期"
                :picker-options="editValidityOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="经营范围" prop="businessScope">
              <el-input v-model="tempForm.businessScope" placeholder="请输入经营范围"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="注册资本(万元)" prop="capital">
              <el-input v-model="tempForm.capital" placeholder="请输入注册资本"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="详细地址" prop="site">
              <el-input v-model="tempForm.site" placeholder="请输入详细地址"/>
            </el-form-item>
          </el-col>

          <el-col :span="16">
            <el-form-item label="请上传营业执照副本">
              <el-upload
                class="upload-demo"
                :action="`${this.getaway.redirect}srm/file/upload`"
                :auto-upload="true"
                :before-upload="beforeAvatarUpload"
                :on-success="getLicense"
                :limit="1">
                <el-button size="small" style="color: #000;">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传jpg文件，且不超过2MB, 只能上传一张</div>
              </el-upload>
            </el-form-item>
          </el-col>

        </el-row>
        <div class="btn-box">
          <el-button @click="submitLogin">下一步</el-button>
        </div>

      </el-form>

    </el-card>
  </div>
</template>
<script>
import cityData from './citydata.js'
import NavBox from '@/components/common/navBox'

export default {
  components: { NavBox },
  data () {
    return {
      tempList: [],
      activeIndex: 2,
      tempForm: {},
      cityData,
      supplierId: '',
      cascaderProps: {
        expandTrigger: 'hover'
        // checkStrictly: true
      },
      rules: {
        name: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        socialCode: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        validity: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        businessScope: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        capital: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        rmb: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        site: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        license: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        principal: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }]
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      },
      editValidityOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now()
        }
      }
    }
  },

  created () {
    this.gettempList()
  },
  mounted () {

  },
  methods: {
    // 通过key接收数据
    async gettempList () {
      const res = await this.axios.get(`${this.getaway.redirect}srm/templates/getTemplateByKey?keyword=SUPPLIER_TEMPLATE`)
      this.tempList = res.data
      this.supplierId = res.data[0].id
    },

    // 上传之后返回的数据
    handleSuccess (response, file, fileList) {
      const arr = []
      const md5 = response.data.md5
      fileList.forEach(f => {
        arr.push({
          name: f.name,
          md5: md5
        })
      })
      this.certifications[this.index].fileList = arr
      this.certifications[this.index].md5.push(md5)
    },

    handleRemove (file, fileList) {
      const md5 = file.response.data.md5
      const arr = []
      fileList.forEach(f => {
        arr.push({
          name: f.name,
          md5: md5
        })
      })
    },

    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传的图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传的图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },

    // 提交表单信息
    async submitLogin () {
      const fromJson = {
        name: this.tempForm.name,
        socialCode: this.tempForm.socialCode,
        addvalidity: this.tempForm.addvalidity,
        editvalidity: this.tempForm.editvalidity,
        businessScope: this.tempForm.businessScope,
        capital: this.tempForm.capital,
        site: this.tempForm.site,
        license: this.tempForm.license,
        principal: this.tempForm.principal
      }

      const dist = {
        json: JSON.stringify(fromJson),
        srmTemplateId: this.supplierId,
        srmSupplierId: sessionStorage.getItem('distId')
      }

      this.$refs.addFormRef.validate(async (valid) => {
        if (valid) {
          await this.axios
            .post(`${this.getaway.redirect}srm/supplier_templates`, dist)
            .then((res) => {
              if (Number(res.code) === 0) {
                sessionStorage.setItem('tempId', res.data.id)
                this.$router.push('/audit')
              }
            })
        } else {
          return false
        }
      })
    },

    getLicense (file) {
      this.tempForm.license = file.data.md5
    },
    quit () {
      this.$router.push('/index')
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-step__head.is-success {
  color: #3150E9;
}

/deep/ .el-step__title.is-success {
  color: #3150E9;
}

/deep/ .el-step__head.is-success {
  border-color: #3150E9;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100%;
}

.el-cascader {
  width: 100%;
}

.box {
  width: 1200px;
  margin: 20px auto;
  box-sizing: border-box;
  text-align: right;
  color: #ccc;

  > i {
    cursor: pointer;
  }
}

.el-card {
  width: 70%;
  margin: 0 auto;
}

.el-steps {
  width: 80%;
  margin: 20px auto;
}

.form-box {
  width: 80%;
  margin: 0 auto;
}

.note {
  width: 50%;
  margin-right: 20px;
}

/deep/ .verify {
  position: relative;

  .el-input__inner {
    width: 60%;
  }

  .pic {
    position: absolute;
    top: 0;
    right: 22px;
    width: 120px;
    height: 100%;
    border: 1px solid #dcdfe6;
  }
}

.btn-box {
  // display: block;
  margin: 20px auto;
  width: 200px;
}

.note-box {
  width: 40%;
  padding: 12px 0;
}
</style>
